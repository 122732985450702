import React, { useState } from "react";
import {
  Button,
  TextField,
  Container,
  Alert,
  AlertTitle,
} from "@mui/material";
import emailjs from 'emailjs-com';
import HCaptcha from '@hcaptcha/react-hcaptcha';

// Function to validate email format
function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

// Add a function to handle form submission
async function handleFormSubmit(event, name, email, message, hcaptchaToken, setAlert) {
    event.preventDefault();

    if (!isValidEmail(email)) {
        setAlert({ severity: 'error', message: 'Please enter a valid email address.' });
        return;
    }

    if (!hcaptchaToken) {
        setAlert({ severity: 'error', message: 'Please complete the CAPTCHA.' });
        return;
    }

    const templateParams = {
        from_name: name,
        from_email: email,
        message: message,
        'h-captcha-response': hcaptchaToken, // Include the hCaptcha token
    };

    try {
        const result = await emailjs.send(
            'webarchive_mailtrap_test', // Replace with your EmailJS service ID
            'web_arch_test_template', // Replace with your EmailJS template ID
            templateParams,
            '6cF4gUX4ul7Vuk4zf' // Replace with your EmailJS user ID
        );
        setAlert({ severity: 'success', message: 'Message sent successfully!' });
    } catch (error) {
        setAlert({ severity: 'error', message: 'Failed to send message.' });
    }
}

// Add the contact form to the view
const ContactView = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [hcaptchaToken, setHcaptchaToken] = useState(null);
    const [alert, setAlert] = useState(null);

    return (
        <Container style={{ maxWidth: "1000px" }}>
            <h1>Contact</h1>
            {alert && (
                <Alert severity={alert.severity} onClose={() => setAlert(null)}>
                    <AlertTitle>{alert.severity === 'success' ? 'Success' : 'Error'}</AlertTitle>
                    {alert.message}
                </Alert>
            )}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ flex: 1, display: 'flex', alignItems: 'center', paddingRight: '20px' }}>
                    <form onSubmit={(e) => handleFormSubmit(e, name, email, message, hcaptchaToken, setAlert)} style={{ width: '100%' }}>
                        <div style={{ marginBottom: '5px' }}>
                            <label htmlFor="name"></label>
                            <TextField 
                                id="name"
                                label="Name"
                                variant="outlined"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                                style={{ width: '100%', boxSizing: 'border-box', paddingBottom: '10px' }}
                            />
                        </div>
                        <div style={{ marginBottom: '5px' }}>
                            <label htmlFor="email"></label>
                            <TextField 
                                id="email"
                                label="Email"
                                variant="outlined"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                style={{ width: '100%', boxSizing: 'border-box', paddingBottom: '10px' }}
                            />
                        </div>
                        <div style={{ marginBottom: '5px' }}>
                            <label htmlFor="message"></label>
                            <TextField 
                                id="message" 
                                label="Message"
                                variant="outlined"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                required
                                style={{ width: '100%', boxSizing: 'border-box', paddingBottom: '10px' }}
                                multiline
                                rows={5}
                            ></TextField>
                        </div>
                        <div style={{ marginBottom: '5px' }}>
                            <HCaptcha
                                sitekey="50e63f7c-1a14-4fe0-a042-49426056e688" // Replace with your hCaptcha site key
                                onVerify={(token) => setHcaptchaToken(token)}
                            />
                        </div>
                        <div style={{ textAlign: 'right' }}>
                            <Button type="submit" variant="contained">Send</Button>
                        </div>
                    </form>
                </div>
                <div style={{ flex: 1 }}>
                    <h1>Welcome to our state-of-the-art website archiving service!</h1>
                    <p>
                        Our platform is the fastest and most reliable archiving solution on the
                        market. With our modern technology and innovative approach, we can
                        archive your website in record time and store it securely on S3
                        certified host.
                    </p>
                    <p>
                        What sets us apart from our competitors is that we offer full support
                        for archiving YouTube and Twitter content, in addition to standard web
                        content. Our cutting-edge archiving tool allows you to easily capture
                        and preserve multimedia content, ensuring that you never lose access to
                        your favorite videos, tweets, and other media.
                    </p>
                    <p>
                        Our service offers unparalleled speed, accuracy, and efficiency, making
                        us the preferred choice for businesses and individuals who demand the
                        best. Whether you need to archive a single page or an entire website, we
                        have the tools and expertise to get the job done quickly and
                        effectively. Try our service today and experience the difference for
                        yourself!
                    </p>
                </div>
            </div>
        </Container>
    );
};

export default ContactView;