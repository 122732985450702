import React from "react";
import format from "date-fns/format";
import { useParams, Link as RdLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import LaunchIcon from "@mui/icons-material/Launch";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Box,
  Link,
  Skeleton,
  Alert,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  IconButton,
  Tooltip,
  Stack,
  useTheme,
  Divider,
  useMediaQuery
} from "@mui/material";

import { formatDateTime } from "../helpers/dateHelper";
import {
  getArchieveListByUrl,
  getDownloadUrl
} from "../services/webArchiveService";
import logo from "../static/webarchiveicon.svg";

import "./archiveView.css";

export default function ArchiveView() {
  const [archive, setArchive] = React.useState(null);
  const [archiveDownloadUrl, setArchiveDownloadUrl] = React.useState(null);

  const [showMenu, setShowMenu] = React.useState(true);
  const [processingState, setProcessingState] = React.useState("LOADING");
  const [index, setIndex] = React.useState(1);

  const theme = useTheme();
  const location = useLocation();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { shortId } = useParams();
  const url = useParams()["*"];
  const hasArchiveVersion = archive?.versions?.count > 1;
  const queryParams = new URLSearchParams(location.search);
  const delayRender = queryParams.get("webarchivescreen");

  const [selectedValue, setSelectedValue] = React.useState(
    delayRender ? "screen" : "archive"
  );

  //console.log("Delay", delayRender);

  React.useEffect(() => {
    hideBodyScroll();
    getArchieveListByUrl(url, shortId).then((x) => {
      document.title = x.title || "webarchive.io";
      if (x.status === "completed") {
        setProcessingState("DONE");
        setArchive(x);
        getDownloadableLinks(x?.jobId);
      } else {
        setProcessingState("Failed");
      }
    });
    return () => showBodyScroll();
  }, [url, shortId]);

  React.useEffect(() => {
    if (index > 1) {
      getDownloadableLinks(archive.versions.archives[index - 1].jobId);
    }
  }, [index]);

  React.useEffect(() => {
    if (selectedValue === "screen") {
      showBodyScroll();
    } else {
      hideBodyScroll();
    }
  }, [selectedValue]);

  const hideBodyScroll = () => {
    document.body.style.overflow = "hidden";
  };

  const showBodyScroll = () => {
    document.body.style.overflow = "auto";
  };

  function getDate() {
    if (!archive || !archive.versions) {
      return;
    }
    const d1 = format(
      new Date(archive.versions.firstVersionDate),
      "yyyy-MM-dd"
    );
    const d2 = format(
      new Date(archive.versions.latestVersionDate),
      "yyyy-MM-dd"
    );
    return `${d1} - ${d2}`;
  }

  function getShortId() {
    if (index > 1) {
      return archive.versions.archives[index - 1].shortId || "";
    }
    return archive.shortId || "";
  }

  function getArchiveData() {
    if (index > 1) {
      return formatDateTime(archive.versions.archives[index - 1].created);
    }
    return formatDateTime(archive.created);
  }

  function navigateVersion(directionNext) {
    if (index === archive?.versions?.count || index === 0) {
      setIndex(1);
    }
    if (directionNext) {
      setIndex(index + 1);
    } else {
      setIndex(index - 1);
    }
  }

  function getDownloadableLinks(jobId) {
    getDownloadUrl(jobId).then((x) => {
      setArchiveDownloadUrl(x);
    });
  }

  const handleValueChange = (event, newValue) => {
    setSelectedValue(newValue);
  };

  function titleValueElemnt(title, value, showTooltip = false) {
    return (
      <Box display={"flex"} flexDirection={"row"}>
        <Typography
          variant="caption"
          sx={{
            fontWeight: "500",
            color: "rgb(131, 146, 171)",
            marginRight: "5px"
          }}
        >
          {title}
        </Typography>

        {showTooltip ? (
          <Tooltip title={value}>
            <Typography
              variant="caption"
              sx={{
                fontWeight: "700",
                color: "rgb(103, 116, 142)"
              }}
            >
              {value}
            </Typography>
          </Tooltip>
        ) : (
          <Typography
            variant="caption"
            sx={{
              fontWeight: "700",
              color: "rgb(103, 116, 142)"
            }}
          >
            {value}
          </Typography>
        )}
      </Box>
    );
  }
  function getHeader() {
    return (
      <Box
        style={{ background: "#fafafb", borderBottom: "1px solid #c7cbcf" }}
        display={"flex"}
        flexDirection={"column"}
        padding={"5px"}
        width="100%"
      >
        {titleValueElemnt("URL", archive.url, true)}

        <Box
          style={{ background: "#fafafb" }}
          display={"flex"}
          justifyContent={"space-between"}
          marginTop={"3px"}
        >
          {titleValueElemnt("Id#", getShortId())}
          {titleValueElemnt("Archived Date", getArchiveData())}
        </Box>

        <Box
          style={{ background: "#fafafb" }}
          display={"flex"}
          justifyContent={"space-between"}
          marginTop={"3px"}
        >
          {titleValueElemnt("Archive Dates", getDate())}
          <Stack direction="row">
            {titleValueElemnt(`Show Archives(${archive?.versions?.count})`, "")}
            <RdLink state={archive} to={`/list/${archive.url}`}>
              <Stack direction="row">
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: "700",
                    color: "rgb(103, 116, 142)"
                  }}
                >
                  open
                </Typography>
                <LaunchIcon style={{ width: "18px", marginLeft: "4px" }} />
              </Stack>
            </RdLink>
          </Stack>
        </Box>
        <Box
          style={{ background: "#fafafb" }}
          display={"flex"}
          justifyContent={"space-between"}
          marginTop={"3px"}
        >
          <ToggleButtonGroup
            exclusive
            value={selectedValue}
            style={{ marginTop: "8px" }}
            color={"primary"}
            onChange={handleValueChange}
          >
            <ToggleButton size="small" value="archive">
              Archive
            </ToggleButton>
            <ToggleButton size="small" value="screen">
              Screenshot
            </ToggleButton>
          </ToggleButtonGroup>

          <Box alignSelf={"end"} minWidth={"40px"}>
            {archive?.versions?.count > 1 && (
              <>
                <IconButton
                  onClick={() => navigateVersion(true)}
                  disabled={index === archive?.versions?.count}
                >
                  <NavigateBeforeIcon />
                </IconButton>
                <IconButton
                  onClick={() => navigateVersion(false)}
                  disabled={index === 1}
                >
                  <NavigateNextIcon />
                </IconButton>
              </>
            )}
          </Box>
        </Box>
      </Box>
    );
  }

  function getDesktopHeader() {
    return (
      <>
        <Box
          width="100%"
          flexDirection={"row"}
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
          style={{ background: "#fafafb", borderBottom: "1px solid #c7cbcf" }}
        >
          <a href="/">
            <img src={logo} alt="logo" height={"60px"} width="60px" />
          </a>
          <Box
            style={{ background: "#fafafb" }}
            display={"flex"}
            flexDirection={"column"}
            padding={"5px"}
            flexGrow={"0.75"}
          >
            {titleValueElemnt("URL", archive.url, true)}
            <Box
              style={{ background: "#fafafb" }}
              display={"flex"}
              justifyContent={"space-between"}
              marginTop={"3px"}
            >
              {titleValueElemnt("Id#", getShortId())}
              {titleValueElemnt("Archived Date", getArchiveData())}
            </Box>

            {hasArchiveVersion && (
              <Box
                style={{ background: "#fafafb" }}
                display={"flex"}
                justifyContent={"space-between"}
                marginTop={"3px"}
              >
                {titleValueElemnt("Archive Dates", getDate())}
                <Stack direction="row">
                  {titleValueElemnt(
                    `Show Archives(${archive?.versions?.count})`,
                    ""
                  )}
                  <RdLink state={archive} to={`/list/${archive.url}`}>
                    <Stack direction="row">
                      <Typography
                        variant="caption"
                        sx={{
                          fontWeight: "700",
                          color: "rgb(103, 116, 142)"
                        }}
                      >
                        open
                      </Typography>
                      <LaunchIcon
                        style={{ width: "18px", marginLeft: "4px" }}
                      />
                    </Stack>
                  </RdLink>
                </Stack>
              </Box>
            )}
            <Box
              style={{ background: "#fafafb" }}
              display={"flex"}
              justifyContent={"space-between"}
              marginTop={"3px"}
            ></Box>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Stack
            direction="row"
            alignItems={"end"}
            justifyContent={"end"}
            marginRight={hasArchiveVersion ? 0 : "70px"}
          >
            <ToggleButtonGroup
              exclusive
              value={selectedValue}
              style={{ marginBottom: "8px", maxHeight: "35px" }}
              color={"primary"}
              onChange={handleValueChange}
            >
              <ToggleButton size="small" value="archive">
                Archive
              </ToggleButton>
              <ToggleButton size="small" value="screen">
                Screenshot
              </ToggleButton>
            </ToggleButtonGroup>

            <Box alignSelf={"end"} minWidth={"40px"}>
              {archive?.versions?.count > 1 && (
                <>
                  <IconButton
                    onClick={() => navigateVersion(true)}
                    disabled={index === archive?.versions?.count}
                  >
                    <NavigateBeforeIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => navigateVersion(false)}
                    disabled={index === 1}
                  >
                    <NavigateNextIcon />
                  </IconButton>
                </>
              )}
            </Box>
          </Stack>
        </Box>
      </>
    );
  }

  return (
    <>
      {showMenu && (
        <Box
          style={{
            minHeight: "30px",
            display: "flex"
          }}
        >
          {processingState === "LOADING" && (
            <Skeleton variant="rounded" width={"100%"} height={50} />
          )}
          {archive && <>{isMobile ? getHeader() : getDesktopHeader()}</>}
        </Box>
      )}
      <Link
        style={{
          position: "fixed",
          right: "10px",
          top: "5px",
          background: "#E0E0E0",
          padding: "4px"
        }}
        component="button"
        variant="body2"
        onClick={() => {
          setShowMenu(!showMenu);
        }}
      >
        {showMenu ? "Hide menu" : "Show menu"}
      </Link>
      {processingState === "FAILED" && (
        <Alert severity="error">
          Archived website not found or somewent wrong....!
        </Alert>
      )}

      <MyWebComponentWrapper
        archiveDownloadUrl={archiveDownloadUrl}
        actualUrl={archive?.url}
        isScreenShot={selectedValue === "screen"}
      />
    </>
  );
}

const MyWebComponentWrapper = ({
  archiveDownloadUrl,
  actualUrl,
  isScreenShot
}) => {
  if (!archiveDownloadUrl) return null;
  if (isScreenShot) {
    return (
      <img
        width={"100%"}
        src={archiveDownloadUrl.screenShotUrl}
        alt="screenshot"
      />
    );
  }
  return (
    <Box style={{ minWidth: "100%", minHeight: "100%" }}>
      <replay-web-page
        embed="replayonly"
        style={{ height: "100vh" }}
        className="frmfullheight"
        source={archiveDownloadUrl?.archiveUrl}
        url={actualUrl}
      ></replay-web-page>
    </Box>
  );
};
